<template></template>
<script>
import HttpCommonMixin from "@/components/CRM/HttpCommonMixin.vue";
import { mapActions, mapState } from "vuex";

export default {
  name: "RoleMixin",
  mixins: [HttpCommonMixin],
  computed: {
    ...mapState(["roles"]),
  },
  methods: {
    ...mapActions(["setRoles"]),

    getRolesFromServer(callback, default_func,force=false) {
      if (this.roles.length == 0 || force) {
        console.log("try to get the roles");
        const url = `${process.env.VUE_APP_SERVER_URL}/api/v1/rbac/roles`;
        this.doHttpGet(url, null, callback);
      } else {
        default_func();
      }
    },
    parseRoleResult(result) {
      let roles = [];
      result.forEach((element) => {
        let role = {
          text: element.description,
          value: element.name,
          id: element.id,
        };
        roles.push(role);
      });
      this.setRoles(roles);
    },
  },
};
</script>
